import { createRoot } from 'react-dom/client';
import { Logo } from '@/components/lv1Atoms/Logo';
import dfLogo from '@/src/assets/logo.png';
import dfLogoNotext from '@/src/assets/logo_notext.png';

const container = document.getElementById('logo');

if (container) {
  const language = container.getAttribute('used_language');
  const serviceLogo = container.getAttribute('service_logo');
  const currentLogo = serviceLogo || (language === 'ja' ? dfLogo : dfLogoNotext);

  const root = createRoot(container);
  root.render(
    <Logo img={currentLogo} size={'mx-auto w-96 h-auto'} />
  );
}
